/**
 * 判断是否在IE浏览器中
 * @returns 
 */
export function isIE(){
	if(!!window.ActiveXObject || "ActiveXObject" in window)
		return true;
	else
		return false;
}




