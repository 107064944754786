<template>
  <div>
    <div class="chartcont">
      <swiper :options="option" ref="swipers" v-if="pictureList.length" @slideChangeTransitionStart="onSwiperSlideChangeTransitionStart">
        <swiper-slide v-for="(img, index) in pictureList" :key="index" >
          <div class="slideImage" @click="previewImgList(index)">
            <img :src="img.url"/>
          </div>
          <p class="chartText" :title="img.name">{{img.name}}
          </p>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev" @click="slideClick(false)"></div>
      <div class="swiper-button-next" @click="slideClick(true)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PictureSwiper",
  components: {
  },
  props: {
    pictureList: Array,
  },
  data() {
    return {
      currentIndex: 0,
      isChange: false,
      chartItem: {},
      option: {
        slidesPerView: "auto",
        spaceBetween: 10,
        centeredSlides: true,
        observeParents: true,
        observer: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        speed: 500,
        pagination: {
          el: ".swiper-pagination",
          //clickable :true,
        },
      },
      num: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.swipers.swiper;
    },
  },
  mounted() {
    this.$nextTick(()=>{
      setTimeout(()=>{
        this.setSwiperInit();
      },2000);
    });
  },
  methods: {
    /*展示中间图片*/
    setSwiperInit() {
      let size=this.pictureList.length;
      this.swiper.slideTo(Math.ceil(size/2)-1);
    },
    slideClick(type) {
      if (type) {
        this.currentIndex++;
        this.num++;
        this.chartItem = this.pictureList[this.num];
      } else {
        this.currentIndex--;
        this.num--;
        this.chartItem = this.pictureList[this.num];
      }
    },
    onSwiperSlideChangeTransitionStart(){
    },
    /*大图弹框*/
    previewImgList (index){
      this.$emit('previewImgList',index);
    },

  },
  created() {

  },

};
</script>

<style scoped>

.chartcont {
  height: 550px;
  position: relative;
  z-index: 0;
}
.swiper-container{
  height: 520px;
}
.chartcont .swiper-pagination {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.chartcont .swiper-slide {
  max-width: 448px !important;
  width: auto;
  height: 354px;
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  transition: 300ms;
  transform: scale(1);
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.slideImage{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.chartcont .swiper-slide img {
  height: 100%;
  /*  width: 100%;*/
}
.chartcont .swiper-slide-active,
.chartcont .swiper-slide-duplicate-active {
  transform: scale(1);
  height: 450px;
  margin-top: 0px;
}
.chartcont .swiper-button-prev {
  background: rgba(51, 51, 51, 0.32) url("../../assets/images/video/prev.png");
  background-repeat: no-repeat;
  background-size: 18px 32px;
  background-position: center center;
  left: 0;
}
.chartcont .swiper-button-next {
  background: rgba(51, 51, 51, 0.32) url("../../assets/images/video/next.png");
  background-repeat: no-repeat;
  background-size: 18px 32px;
  background-position: center center;
  right: 0;
}
.chartcont .swiper-button-prev,
.chartcont .swiper-button-next {
  top: 50%;
  margin-top: -65px;
  width: 82px;
  height: 129px;
}
.chartcont .swiper-button-prev:after,
.chartcont .swiper-button-next:after {
  content: "";
}
.chartText {
  color: #ffffff;
  font-size: 24px;
  padding-top: 30px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -100px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  opacity: 0;
  cursor: pointer;
}
.swiper-slide-active .chartText{
  opacity: 1;
  bottom: -60px;
}
.chartText .tooltip{
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  font-size: 18px;
  background: #ffffff;
  color: #000000;
  padding: 3px 5px;
  border-radius: 5px;
  max-width: 100%;
}

.chartcont .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 18px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  margin: 0px 4px;
}
.chartcont .swiper-pagination .my-bullet-active {
  display: inline-block;
  background: #ffffff;
  opacity: 1;
}
</style>
