<template>
  <div >
      <div id="box" class="music">
        <img v-show="showStaticPic" src="../../assets/images/audio/static-music-pic.png" style="width: 100%; "/>
        <img v-show="showDynamicPic" src="../../assets/images/audio/2022-3-2.gif" style="width: 88%; "/>
        <canvas v-show="!showStaticPic" ref="myCanvas"></canvas>
      </div>
      <div id="music-left" class="music">
        <ul>
          <li @click="onClick(item.url,item.id)" v-for="item in audioData" :key="item.id"
            :class= "yinyue == item.id ? 'active' : '' " >
            <img src="../../assets/images/audio/yinyue1.png" class="moimg">
            <img src="../../assets/images/audio/yinyue.png" class="acimg">
            {{ item.name }}
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import { clickMusic, musicInit } from "../../assets/js/audio";
import { isIE } from '../../utils/common';
export default {
  name: "AudioSwiper",
  components: {
  },
  props: {
    audioData: Array,
  },
  data() {
    return {
      yinyue:0,
      playData:[],
      showStaticPic:true,
      showDynamicPic:false,
    };
  },
  mounted() {
    this.$nextTick(()=>{
      setTimeout(()=>{
        let myCanvas=this.$refs.myCanvas;
        musicInit(myCanvas);
      },2000)
    })
  },
  created() {
  },
  methods: {
    onClick(url,id) {
      this.showStaticPic=false;
      this.yinyue = id;
      if(isIE()){
        this.showDynamicPic=true;
      }
      clickMusic(url);     
    },
  },
};
</script>
<style scoped>
.sound {
  height: 520px;
  width: 100%;
  background-image: url("../../assets/images/audio/bgnew.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/*音乐*/
#box{
  width: 1200px;
  height: 300px;
  margin: auto;
  padding-top: 50px;
}
#music-left{
  padding-top: 50px;
}
#music-left ul{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}
#music-left ul li{
  padding: 14px 40px;
  border-radius: 30px;
  background: #FFFFFF;
  margin-right: 10px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  color: #857176;
  cursor: pointer;
  transition: all .3s;
  position: relative;
}
#music-left ul li:last-child{
  margin-right: 0;
}
/*#music-left ul li:hover{
  color: #ffffff;
  background: #FF5252;
}*/
#music-left ul .active{
  color: #ffffff;
  background: #FF5252;
}
#music-left ul li img{
  position: absolute;
  left: -10px;
  width: 40px;
  height: 46px;
  top: 5px;
  transition: all .3s;
}
#music-left ul li .acimg{
  opacity: 0;
}
#music-left ul li .moimg{
 /* animation:yinyue linear infinite 2s;*/
  opacity: 1;
}
/*#music-left ul li:hover img{
  animation:yinyue linear infinite 2s;
  opacity: 1;
}*/
#music-left ul .active .acimg{
  animation:yinyue linear infinite 2s;
  opacity: 1;
}
#music-left ul .active .moimg{
  opacity: 0;
}
@keyframes yinyue {0% {top:0px;} 50% {top:7px;} 100% {top:0px;}}
/**/
</style>

