<template>
  <div class="box_flex">
        <div class="leftArrow" @click="arrowClick('left')">
          <img src="../../assets/images/video/prev.png" />
        </div>
        <el-carousel :interval="4000" type="card" :autoplay="false" arrow="never" :loop="false"
          :initial-index="videoIndex" @change="onSlideChange" trigger="click" ref="cardShow">
          <el-carousel-item v-for="(list, index) in swiperData" :key="index">
            <div style="height: 100%">
              <div :class="'vodeoPlay' + [list.play ? ' vodeoPause' : '']" @click="play(index)" v-show="(showIcon && list.play) || !list.play">
              </div>
              <video muted controls="controls" controlslist="nodownload noremoteplayback" :ref="'video' + index" :poster="list.titleImg" preload="none">
                <source :src="list.url" />
              </video>
              <div class="marker" v-for="(tag, idx) in showTagList" :style="{ right: tag.x, bottom: tag.y }" :key="idx"
                v-show="videoIndex == index">
                <div class="box_flex">
                  <div class="caption">
                    <span>{{ tag.msg }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="shades"></div>
          </el-carousel-item>
        </el-carousel>
        <div class="rightArrow" @click="arrowClick('right')">
          <img src="../../assets/images/video/next.png" />
        </div>
      </div>
</template>

<script>
export default {
  name: "VideoSwiper",
  components: {

  },
  props: {
    swiperData: Array,
  },
  data() {
    return {
      showTagList: [],
      showIcon: false,
      videoIns: {},
      videoIndex: 1,
      oldTime: 0,
      isChange: false,
      videoName:{},
      videotext: 1,
      scrollTop:0
    };
  },
  mounted() {

  },
  created() {
  },
  methods: {
    play(index) {
      let videoIns = this.initVideo(index);
      if (videoIns.paused == true) {
        videoIns.play();
        this.showIcon = false;
      } else {
        this.pause();
      }
    },
    pause(index) {
      let videoIns = this.initVideo(index);
      videoIns.pause();
    },
    initVideo(index) {
      let videoRef = `video${index}`;
      if (this.videoIns[videoRef]) {
        return this.videoIns[videoRef];
      } else {
        this.videoIndex = index;
        this.videoIns[videoRef] = this.$refs[`video${index}`][0];
        this.videoIns[videoRef].addEventListener(
          "timeupdate",
          this.videoTimeUpdate
        );
        this.videoIns[videoRef].addEventListener("play", this.videoPlay);
        this.videoIns[videoRef].addEventListener("pause", this.videoPause);
        return this.videoIns[videoRef];
      }
    },
    // 视频播放
    videoPlay() {
      this.showTagList = [];
      let index = this.videoIndex;
      this.$set(this.swiperData, index, {
        ...this.swiperData[index],
        play: true,
      });
    },
    // 视频暂停
    videoPause() {
      let index = this.videoIndex;
      this.$set(this.swiperData, index, {
        ...this.swiperData[index],
        play: false,
      });
    },
    // 视频更新
    videoTimeUpdate(e) {
      let currentTime = Math.floor(e.target.currentTime);
      if (this.oldTime != currentTime) {
        this.showTag(currentTime, this.videoIndex);
      }
      this.oldTime = currentTime;
    },
    showTag(currentTime, index) {
      if (this.swiperData[index].tagList[currentTime]) {
        this.showTagList = this.swiperData[index].tagList[currentTime];
        this.initVideo(index).pause();
      }
    },
    onSlideChange(index, oldIndex) {
      this.showTagList = [];
      this.oldTime = 0;
      this.isChange = false;
      this.videoIndex = index;
      this.initVideo(index);
      this.pause(oldIndex);
    },
    /*左右按钮*/
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
        this.videotext ++;
        this.videoName = this.swiperData[this.videotext];
      } else {
        this.$refs.cardShow.prev();
        this.videotext --;
        this.videoName = this.swiperData[this.videotext];
      }
    },
  },
};
</script>

<style scoped>
.box_flex >>> .el-carousel__container {
  height: 470px;
}
.box_flex >>> .el-carousel__mask {
  background: none;
}
.box_flex >>> .el-carousel__indicators--outside {
  margin-top: 70px;
}

/*轮播*/
.el-carousel {
  height: 570px;
  width: 85%;
  margin: auto;
  z-index: 0;
}
.el-carousel__item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*弹出锚点*/
.marker {
  cursor: pointer;
  position: absolute;
  z-index: 999999;
}
.caption {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
}
.caption span {
  background: #668b78;
  display: inline-block;
  padding: 10px;
  border-radius: 4px 0px 0px 4px;
  border-right: 5px solid #4d6f5e;
  box-sizing: border-box;
}
/*图标*/
.el-carousel__item .vodeoPlay {
  opacity: 0;
}
.is-active .vodeoPlay {
  opacity: 1;
}
.vodeoPlay {
  background-image: url("../../assets/images/video/play.png");
  width: 80px;
  height: 80px;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 3;
}
.chara {
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  position: relative;
  top: -75px;
}

.blank{
  height: 3px;
  width: 100%;
  background: #ffffff;
}
.box_flex >>> .el-carousel__indicators {
  pointer-events: none;
}
.details {
  display: flex;
  justify-content: center;
}
.onself {
  width: 846px;
  border-bottom: 1px solid rgba(179, 179, 179, 0.29);
  padding-bottom: 20px;
}
.headle {
  justify-content: center;
}

.headle {
  padding-bottom: 40px;
}
.headle span {
  font-size: 28px;
  font-weight: bold;
  color: #1a1a1a;
  padding: 0px 30px;
  flex-shrink: 0;
  opacity: 0.86;
}
.details .onself p {
  font-size: 18px;
  color: #1a1a1a;
  line-height: 48px;
  text-align: left;
  text-indent: 36px;
}
.details >>> .onself p img {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: -36px;
  max-width: 100%;
}
.clauseu p {
  font-size: 20px;
  color: #1a1a1a;
  text-align: left;
  padding: 30px 0px 20px 0px;
}
.clauseu ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.clauseu li {
  background: #e6e6e6;
  border-radius: 6px;
  padding: 16px 50px 16px 16px;
  font-size: 14px;
  color: #1a1a1a;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.clauseu li span {
  display: inline-block;
  width: 9px;
  height: 9px;
  background: #999999;
  transform: rotate(45deg);
  margin-right: 11px;
}
.marker {
  cursor: pointer;
  position: absolute;
  z-index: 999999;
}
.caption {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
}
.caption span {
  background: #668b78;
  display: inline-block;
  padding: 10px;
  border-radius: 4px 0px 0px 4px;
  border-right: 5px solid #4d6f5e;
  box-sizing: border-box;
}
video {
  width: 100%;
  height: 100%;
}

.leftArrow,
.rightArrow {
  width: 82px;
  height: 129px;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 0;
  text-align: center;
  line-height: 129px;
  cursor: pointer;
}
.leftArrow img,
.rightArrow img {
  vertical-align: middle;
}

.shades {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}
.is-active .shades {
  display: none;
}
/*分页器*/
.video_carous >>> .el-carousel__button {
  width: 18px;
  height: 4px;
  border-radius: 2px;
  background-color: #ffffff;
}

</style>

