
/* eslint-disable */
import musicVisualizer from './musicVisualizer';
import { isIE } from '../../utils/common';
var size = 64;
var box = null;
var height, width;
var canvas = null;
var ctx = null;
var Dots = [];
draw.type = "column";
var line;
var mv;
let oldUrl;

export function musicInit(myCanvas) {
    if(!isIE()){
        mv = new musicVisualizer({
            size: size,
            visualizer: draw,
            ac: new (window.AudioContext || window.webkitAudioContext)()
        });
        box = document.getElementById("box");
        canvas=myCanvas;
        ctx = canvas.getContext("2d");
        window.onresize = resize;
        resize();
    }else{
        mv=new Audio();
        mv.volume=0.5;
    }
    
}

export async function clickMusic(url) {
    if(!isIE()){
        if (oldUrl != url) {
            mv.play();
            mv.stop();
            await mv.load(url);
            oldUrl = url;
        } else {
            if (mv.isPlay) {
                mv.pause()
            } else {
                mv.play();
            }
        }
    }else{
        if(oldUrl != url){
            mv.src=url; 
            mv.play();
            oldUrl=url;
        }else{
            if(mv.paused){
                mv.play();
            }else{
                mv.pause();
            }
        }
        
    }
    
}

function random(m, n) {
    return Math.round(Math.random() * (n - m) + m);
}

function getDots() {
    Dots = [];
    for (var i = 0; i < size; i++) {
        var x = random(0, width);
        var y = random(0, height);
        var color = "rgba(" + random(0, 255) + "," + random(0, 255) + "," + random(0, 255) + ",0)";
        Dots.push({
            x: x,
            y: y,
            color: color,
            cap: 0,
            dx: random(1, 4)
        });
    };
}


function resize() {
    height = box.clientHeight;
    width = box.clientWidth;
    canvas.height = height;
    canvas.width = width;
    line = ctx.createLinearGradient(0, 0, 0, height);
    line.addColorStop(0, "pink");
    line.addColorStop(0.5, "grey");
    line.addColorStop(1, "lightblue");
    getDots();
}


function draw(arr) {
    ctx.clearRect(0, 0, width, height);
    var w = width / size;
    var cw = w * 0.6;
    var ch = cw;
    ctx.fillStyle = line;
    for (var i = 0; i < size; i++) {
        var o = Dots[i];
        if (draw.type == "column") {
            var h = arr[i] / 256 * height;
            ctx.fillRect(w * i, height - h, cw, h);
            ctx.fillRect(w * i, height - (o.cap + ch), cw, ch);
            o.cap--;
            if (o.cap < 0) {
                o.cap = 0;
            }
            if (h > 0 && o.cap < h + 30) {
                o.cap = h + 30 > height - ch ? height - ch : h + 30;
            }
        } else if (draw.type == "dot") {
            ctx.beginPath();
            var r = 10 + arr[i] / 256 * (height > width ? width : height) / 10;
            ctx.arc(o.x, o.y, r, 0, Math.PI * 2, true);
            var circle = ctx.createRadialGradient(o.x, o.y, 0, o.x, o.y, r);
            circle.addColorStop(0, "white");
            circle.addColorStop(1, o.color);
            ctx.fillStyle = circle;
            ctx.fill();
            o.x += o.dx;
            o.x = o.x > width ? 0 : o.x;
        }
    }
}
